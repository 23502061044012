import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';

type ActionType = 'UPDATE' | 'DELETE' | 'CREATE';

@Injectable({
    providedIn: 'root',
})
export class HandleHttpSubscribe {
    constructor(
        private readonly notificationService: NzNotificationService,
        private readonly translateService: TranslateService,
    ) {}

    /**
     * handle http error repose
     */
    handleError(httpErrorResponse: HttpErrorResponse): void {
        const { error } = httpErrorResponse;
        const { errorCode, errorParams } = error;
        const errorTitle = this.translateService.instant('MESSAGE.ERROR');
        const messageKey = `MESSAGE.${errorCode}`;
        const messageTranslate = this.translateService.instant(
            messageKey,
            errorParams,
        );
        const message =
            messageTranslate !== messageKey
                ? messageTranslate
                : error.errorMessage ||
                  error.message ||
                  error.errorCode ||
                  error.code;
        this.notificationService.error(errorTitle, message);
    }

    /**
     * handle http success repose
     */
    handleSuccess(type: ActionType, completeCallBack: () => any): void {
        let title = '';
        switch (type) {
            case 'CREATE':
                title = this.translateService.instant('MESSAGE.CREATE_SUCCESS');
                this.notificationService.success(title, '');
                break;
            case 'UPDATE':
                title = this.translateService.instant('MESSAGE.UPDATE_SUCCESS');
                this.notificationService.success(title, '');
                break;
            case 'DELETE':
                title = this.translateService.instant('MESSAGE.DELETE_SUCCESS');
                this.notificationService.success(title, '');
                break;
            default:
        }
        if (completeCallBack) {
            completeCallBack();
        }
    }

    /**
     * handle success and error of obs
     */
    handleObs<T>(
        obs: Observable<T>,
        type: ActionType,
        completeCallBack: (res: T) => any = () => {},
        errorCallBack?: (err: T) => any,
    ): void {
        obs.subscribe({
            next: (res) => {
                this.handleSuccess(type, completeCallBack(res));
            },
            error: (err) => {
                this.handleError(err);
                if (errorCallBack) {
                    errorCallBack(err);
                }
            },
        });
    }
}
